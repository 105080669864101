import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Esto es para accesibilidad

const Terms = ({ isOpen, onClose, htmlContent }) => {
    return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Terms of Service"
                style={{
                    content: {
                        top: "10%",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        margin: "0",
                        padding: "20px",
                        height: "100%", // Altura completa
                        overflowY: "auto", // Para permitir desplazamiento si el contenido es largo
                    },
                    overlay: {backgroundColor: "rgba(0, 0, 0, 0.7)"},
                }}
            >
                <h2>Terms of Service</h2>
                <div
                    style={{maxHeight: "75%", overflowY: "auto"}}
                    dangerouslySetInnerHTML={{__html: htmlContent}}
                ></div>
                <center>
                    <button className="generic-button" onClick={onClose} style={{marginTop: "20px", marginBottom: "20px"}}>
                        Close
                    </button>
                </center>
            </Modal>
            );
            };

            export default Terms;
